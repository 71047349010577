import { InjectionToken } from '@angular/core';

export interface Environments {
  production: boolean;
  brand: string;
  phone: string;
  google?: {
    id: string;
    name: string;
    key: string;
  };
  appHost: string;
  firebase?: any;
  imageSize?: string;
}

export const ENVIRONMENTS = new InjectionToken<Environments>('ENVIRONMENTS');

export const ENVIRONMENTS_DEFAULT: Environments = {
  production: false,
  brand: 'MN COLLECTION',
  phone: '88002000000',
  appHost: 'http://localhost',
  imageSize: '1680x1680',
};
