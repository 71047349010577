<ng-container [ngSwitch]="layoutType$ | async">
  <ng-container *ngSwitchDefault>
    <div>
      <lightstore-header></lightstore-header>
      <main automation-id="handset">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </main>
    </div>
    <lightstore-menu></lightstore-menu>
  </ng-container>
  <ng-container *ngSwitchCase="breakpoints.Tablet">
    <lightstore-sidebar></lightstore-sidebar>
    <main automation-id="tablet">
      <ng-container *ngTemplateOutlet="template; context: { mode: 'fluid' }"></ng-container>
    </main>
  </ng-container>
  <ng-container *ngSwitchCase="breakpoints.Web">
    <lightstore-header></lightstore-header>
    <main automation-id="web">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </main>
    <lightstore-footer></lightstore-footer>
  </ng-container>
</ng-container>

<ng-template #template let-mode="mode">
  <router-outlet name="top"></router-outlet>
  <lightstore-container [mode]="mode">
    <router-outlet></router-outlet>
  </lightstore-container>
  <router-outlet name="bottom"></router-outlet>
</ng-template>
