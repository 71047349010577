import { makeStateKey } from '@angular/platform-browser';

export enum ProductKeys {
  Products = 'products',
}

export const PRODUCTS_META = makeStateKey('products');

export interface Product {
  id: string;
  title: string;
  subtitle: string;
  price: number;
  sizes: any[];
  description: string;
  images: string[];
  slug: string;
  active: boolean;
  preorder: boolean;
  category: ProductCategories[];
}

export interface ProductsResponse {
  range: string;
  majorDimension: string;
  values: [string, string, string, string, string, string, string][];
}

export enum ProductField {
  Size = 'size',
  Count = 'count',
}

export enum ProductCategories {
  Dresses = 'Dresses',
  Blazer = 'Blazers',
  Skirts = 'Skirts',
  Trousers = 'Trousers',
  Shorts = 'Shorts',
  Sweatshirts = 'Sweatshirts',
  Shirts = 'Shirts',
  TShirts = 'T-Shirts',
  Coats = 'Coats / Trench Coats',
  Accessories = 'Accessories',
  Coords = 'Co-ords',
}

export enum ProductSizes {
  S = 'S',
  M = 'M',
  L = 'L',
}

