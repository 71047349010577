import { CartProduct } from './cart.interface';

export const CART_PRODUCT_STUB: CartProduct = {
  productId: '1111',
  count: 1,
  size: 'M',
};

export const CART_PRODUCT_NEXT_STUB: CartProduct = {
  productId: '2222',
  count: 2,
  size: 'S',
};

export const CART_PRODUCTS_STUB: CartProduct[] = [CART_PRODUCT_STUB, CART_PRODUCT_NEXT_STUB];
