import { NgModule } from '@angular/core';
import { getAnalytics,provideAnalytics,ScreenTrackingService,UserTrackingService } from '@angular/fire/analytics';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { getAuth,provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { getFirestore,provideFirestore } from '@angular/fire/firestore';
import { getFunctions,provideFunctions } from '@angular/fire/functions';
import { getRemoteConfig,provideRemoteConfig } from '@angular/fire/remote-config';
import { getStorage,provideStorage } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { provideAppCheck, initializeAppCheck, ReCaptchaV3Provider } from '@angular/fire/app-check';

import { HammerModule } from '@lightstore/core/hammer';
import { LocalizationModule } from '@lightstore/russian/localization';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppCoreModule } from './app.core.module';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'lightstore' }),
    LocalizationModule,
    AppRoutingModule,
    AppCoreModule,
    HammerModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideStorage(() => getStorage()),
    // provideAppCheck(() =>
    //   initializeAppCheck(undefined, {
    //     provider: new ReCaptchaV3Provider('6Le766IgAAAAAE1gzTwBdYhdfh4wBC_bXGCnyEA6'),
    //     isTokenAutoRefreshEnabled: true,
    //   })
    // ),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    ScreenTrackingService,UserTrackingService,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase }
  ],
})
export class AppModule {}
