import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/compat/firestore';
import { defer,Observable } from 'rxjs';

import { ApiService } from '@lightstore/core/api/service';
import { Order, OrderCreate, OrderDetails } from '@lightstore/orders/common';

@Injectable()
export class OrdersApiService {
  private orderCollection: AngularFirestoreCollection<OrderCreate>;
  constructor(
    private readonly apiService: ApiService,
    private firestore: AngularFirestore
  ) {
    this.orderCollection = firestore.collection<OrderCreate>('orders');
  }

  createOrder(order: Order): Observable<OrderDetails>{
    console.log({order});
    return defer(() => {
      return this.orderCollection.add(order).then(docRef => {
        return {
          id: (<DocumentReference>docRef).id.slice(0, 5).toUpperCase()
        };
      });
    })
  }
}

