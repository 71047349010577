<lightstore-phone automation-id="phone"></lightstore-phone>
<lightstore-cart automation-id="cart"></lightstore-cart>
<!--<lightstore-support automation-id="support"></lightstore-support>-->
<ng-container *ngIf='(auth.authState | async)?.uid'>
  <div class='user-links'>
    <a class='admin-link'
       #rla="routerLinkActive"
       routerLinkActive
       attr.aria-label="Admin"
       mat-icon-button
       [class.is-active]="rla.isActive"
       [routerLinkActiveOptions]="{ exact: false }"
       routerLink="admin">
      <mat-icon>admin_panel_settings</mat-icon>
    </a>
    <a class='logout-link'
       aria-label="Logout"
       i18n-aria-label="Layout|Logout link"
       mat-icon-button
       (click)="auth.logout()">
      <mat-icon>logout</mat-icon>
    </a>
  </div>
</ng-container>
