import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isEditor = new BehaviorSubject<boolean>(false);

  constructor(public af: AngularFireAuth) {
    this.af.authState
      .subscribe(
        authUser => {
          if (authUser) {
            authUser.getIdTokenResult()
              .then( idTokenResult => {
                const isEditor = !!idTokenResult.claims && idTokenResult.claims['isAdmin'] === true;
                this.isEditor.next(isEditor);
              });
          }
        }
      );
  }

  get authState() {
    return this.af.authState;
  }

  get user() {
    return this.af.user;
  }

  login() {
    return this.af.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  logout() {
    this.isEditor.next(false);
    return  this.af.signOut();
  }
}
