<div *ngIf="auth.user | async as user; else showLogin">
  <h1>Hello {{ user.displayName }}!</h1>
  <button mat-raised-button
          cdkFocusInitial
          (click)="logout()">
    Logout
  </button>
<!--  {{ auth.user | async | json }}-->
<!--  {{ auth.authState | async | json }}-->
<!--  {{ (auth.authState | async)?.uid | json }}-->

  {{ auth.isEditor | async | json }}
</div>
<ng-template #showLogin>
  <button mat-raised-button
          cdkFocusInitial
          (click)="login()">
    Login with Google
  </button>
</ng-template>
