import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RouteData } from '@lightstore/core/navigation/common';

import { LoginComponent } from './containers/login/login.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    data: {
      meta: {
        title: 'Login',
      },
    } as Partial<RouteData>,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
