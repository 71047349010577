import { Environments } from '@lightstore/core/environments/service';

export const environment: Environments = {
  firebase: {
    projectId: 'mnstore-6f6fc',
    appId: '1:905829814578:web:b91b3556c518201442c72b',
    databaseURL: 'https://mnstore-6f6fc-default-rtdb.asia-southeast1.firebasedatabase.app',
    storageBucket: 'mnstore-6f6fc.appspot.com',
    locationId: 'asia-south1',
    apiKey: 'AIzaSyDMj-rx284lqHeBfdVvnvzN-fz1Xjms4l4',
    authDomain: 'mnstore-6f6fc.firebaseapp.com',
    messagingSenderId: '905829814578',
    measurementId: 'G-34EYQB2PCX',
  },
  production: true,
  brand: 'MN COLLECTION',
  phone: '88002000000',
  appHost: 'https://mncollection.in',
  imageSize: '1680x1680',
};
