import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@lightstore/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'lightstore-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent {

  constructor(
    public auth: AuthService,
    private router: Router
  ) {
  }

  login() {
    this.auth.login()
      .then(async () => {
        await this.router.navigate(['/']);
      });
  }

  logout() {
    this.auth.logout()
      .then(async () => {
        await this.router.navigate(['/']);
      });
  }

}
