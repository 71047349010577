import { NgModule } from '@angular/core';
import {
  canActivate,
  hasCustomClaim,
  redirectUnauthorizedTo
} from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';

import { NAVIGATION_PATHS } from '@lightstore/core/navigation/common';
import { OrderGuard, OrderGuardsModule } from '@lightstore/orders/guards';
import { ProductGuard, ProductGuardsModule } from '@lightstore/products/guards';
import { LayoutComponent } from '@lightstore/ui/layout';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
const adminOnly = () => hasCustomClaim('isAdmin');

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: NAVIGATION_PATHS.home,
        loadChildren: () => import('@lightstore/home/page').then((modules) => modules.HomePageModule),
      },
      {
        path: NAVIGATION_PATHS.product,
        canActivate: [ProductGuard],
        loadChildren: () => import('@lightstore/products/page').then((modules) => modules.ProductPageModule),
      },
      {
        path: NAVIGATION_PATHS.cart,
        loadChildren: () => import('@lightstore/cart/page').then((modules) => modules.CartPageModule),
      },
      {
        path: NAVIGATION_PATHS.order,
        canActivate: [OrderGuard],
        loadChildren: () => import('@lightstore/orders/page').then((modules) => modules.OrderPageModule),
      },
      {
        path: NAVIGATION_PATHS.support,
        loadChildren: () => import('@lightstore/chat/page').then((modules) => modules.ChatPageModule),
      },
      {
        path: NAVIGATION_PATHS.terms,
        loadChildren: () => import('@lightstore/russian/terms/page').then((modules) => modules.TermsPageModule),
      },
      {
        path: NAVIGATION_PATHS.login,
        loadChildren: () => import('@lightstore/auth').then((modules) => modules.AuthModule),
      },
      {
        path: NAVIGATION_PATHS.admin,
        loadChildren: () => import('@lightstore/admin').then((modules) => modules.AdminModule),
        ...canActivate(adminOnly),
        ...canActivate(redirectUnauthorizedToLogin)
      },
      {
        path: '',
        loadChildren: () => import('@lightstore/errors/pages').then((modules) => modules.ErrorsPagesModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    OrderGuardsModule,
    ProductGuardsModule,
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
