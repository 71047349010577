<lightstore-nav-link automation-id="nav-link" *ngFor="let link of links; trackBy: trackByFn">
  <a
    mat-button
    #rla="routerLinkActive"
    routerLinkActive
    [attr.aria-label]="link.label"
    [class.is-active]="rla.isActive"
    [routerLinkActiveOptions]="link.routerLinkActiveOptions || { exact: false }"
    [routerLink]="link.route | path"
  >
    <mat-icon *ngIf="link.route !== paths.cart">{{ link.label }}</mat-icon>
    <mat-icon
      matBadgeColor="accent"
      [matBadge]="count$ | async"
      [matBadgeHidden]="(count$ | async) === 0"
      *ngIf="link.route === paths.cart"
    >
      {{ link.label }}
    </mat-icon>
  </a>
</lightstore-nav-link>
<lightstore-nav-link class="phone" automation-id="nav-link">
  <a aria-label="Call to phone" i18n-aria-label="Layout|Call to phone" mat-button href="tel:">
    <mat-icon>phone</mat-icon>
  </a>
</lightstore-nav-link>
<lightstore-nav-link automation-id="nav-link" *ngIf='(auth.authState | async)?.uid'>
  <a
    mat-button
    #rla="routerLinkActive"
    routerLinkActive
    attr.aria-label="Admin"
    [class.is-active]="rla.isActive"
    [routerLinkActiveOptions]="{ exact: false }"
    routerLink="admin"
  >
    <mat-icon>admin_panel_settings</mat-icon>
  </a>
</lightstore-nav-link>
<lightstore-nav-link automation-id="nav-link" *ngIf='(auth.authState | async)?.uid'>
  <a
    mat-button
    (click)="auth.logout()"
  >
    <mat-icon>logout</mat-icon>
  </a>
</lightstore-nav-link>
