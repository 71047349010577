import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, of } from 'rxjs';

import { ApiService } from '@lightstore/core/api/service';
import { EnvironmentService } from '@lightstore/core/environments/service';
import { Product } from '@lightstore/products/common';

@Injectable()
export class ProductApiService {

  constructor(
    private readonly apiService: ApiService,
    private readonly environmentService: EnvironmentService,
    private firestore: AngularFirestore
  ) {
    firestore.collection('items').valueChanges()
      .subscribe((data) => {
        console.log({data});
      })
  }

  load(): Observable<Product[]> {
    if (!this.environmentService.environments.firebase?.projectId) {
      console.warn('Database was not loaded. Check your envs.');

      return of([]);
    }

    return this.firestore
      .collection<Product>('items', ref => ref.where('active', '==', true))
      .valueChanges({ idField: 'id' });
  }

}
