import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AuthService } from '@lightstore/auth';

@Component({
  selector: 'lightstore-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsComponent {
  constructor(
    public auth: AuthService
  ) {}
}
